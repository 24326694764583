<template>
  <div style="padding-bottom: 60px">
    <Header>
      <template>使用球队</template>
      <template v-slot:right>
        <img
          class="doubt"
          @click="anyRuleInfo"
          src="../../../static/image/doubt.png"
          alt=""
        />
      </template>
    </Header>
    <div style="padding: 15px 20px">
      <div class="search">
        <div class="left">
          <input
            type="text"
            v-model="query.name"
            placeholder="输入球队名或编号"
          />
        </div>
        <div class="right">
          <div class="btn" @click="getList(1)">搜索</div>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="title">选择球队：</div>
      <div class="content">
        <div
          class="bg"
          v-for="item in collectionsList"
          :key="item.id"
          @click="collectionsActive = item.id"
        >
          <div class="item">
            <div class="img">
              <img :src="item.goods.listPic" alt="" />
            </div>
            <div class="title">
              <div class="left">{{ item.goodsNumber }}</div>
              <div
                class="right"
                :class="collectionsActive === item.id ? 'active' : null"
              ></div>
            </div>
          </div>
        </div>
        <div v-if="!collectionsList.length" class="def">
          <img src="../../../static/image/emptyBox.png" alt="" />
          <p>暂无可用的二星至四星球队</p>
        </div>
      </div>
    </div>
    <div class="list" v-if="materialList.length">
      <div class="title">选择材料：</div>
      <div class="content">
        <div
          class="bg"
          v-for="item in materialList"
          :key="item.id"
          @click="materialActive = item.id"
        >
          <div class="item">
            <div class="img">
              <img :src="item.goods.listPic" alt="" />
            </div>
            <div class="title">
              <div class="left">{{ item.goodsNumber }}</div>
              <div
                class="right"
                :class="materialActive === item.id ? 'active' : null"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btnBox">
      <div class="btn" @click="submit">确定</div>
    </div>

    <van-popup v-model:show="showPopUp" class="popUp">
      <div class="header">{{ ruleInfo.name }}</div>
      <div class="content" v-html="ruleInfo.rule"></div>
      <div class="bottom" @click="showPopUp = false">我知道了</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleInfo: {},
      query: {
        game_id: '',
        team_id: '',
        pageindex: 1,
        pagesize: 1000,
        type: 1,
        token: '',
        name: '',
      },
      showPopUp: false,
      collectionsList: [], // 藏品列表
      materialList: [], // 材料列表
      collectionsActive: null,
      materialActive: null,
    };
  },
  mounted() {
    this.query.game_id = this.$route.query.id;
    this.query.team_id = this.$route.query.teamId;
    this.query.token = this.$route.query.token;
    // 获取藏品列表
    this.getList(1);
    this.getList(2);
  },
  methods: {
    // 获取规则
    anyRuleInfo() {
      this.showPopUp = true;
      this.$api.anyRuleInfo({ type: 'team_call_rule' }).then((e) => {
        if (e.code === 0) {
          this.ruleInfo = e.data;
        }
      });
    },
    submit() {
      if (!this.collectionsActive) {
        return this.$toast('请选择球队');
      }
      const query = {
        game_id: this.$route.query.id,
        team_id: this.$route.query.teamId,
        position_id: this.collectionsActive,
        guest_id: this.materialActive,
        token: this.$route.query.token,
      };
      this.$api.teamCardgameStake(query).then((e) => {
        if (e.code === 0) {
          this.$router.back();
        }
        this.$toast(e.msg);
      });
    },
    getList(type) {
      this.query.type = type;
      this.$api.getJoinMatch(this.query).then((e) => {
        if (e.code === 0) {
          if (type === 1) {
            this.collectionsList = e.data;
          } else {
            this.materialList = e.data;
          }
        } else {
          this.$toast(e.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.list {
  padding: 0 20px;
  margin-bottom: 20px;
  .title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
  }
  .content {
    margin: 0 -7px;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
    .bg {
      width: 50%;
      box-sizing: border-box;
      padding: 7px;
      display: inline-block;
      .item {
        box-shadow: 0px 0px 5px 2px rgb(1 15 45 / 5%);
        border-radius: 3px;
        overflow: hidden;
        .img {
          position: relative;
          height: 180px;
          .bg {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            background: rgba(98, 98, 98, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          img {
            width: 100%;
            vertical-align: middle;
            object-fit: cover;
            height: 100%;
          }
        }
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 5px;
          .left {
            font-size: 14px;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            white-space: normal;
          }
          .right {
            font-size: 14px;
            width: 10px;
            height: 10px;
            border: 1px solid #cecece;
            position: relative;
            &.active {
              border: 1px solid #0754d3;
              &::before {
                display: block;
                content: '';
                width: 8px;
                height: 5px;
                border-bottom: 2px solid #0754d3;
                position: absolute;
                left: 50%;
                border-left: 2px solid #0754d3;
                top: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                margin-top: -2px;
              }
            }
          }
        }
      }
    }
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    /*定义滚动条轨道 内阴影+圆角*/
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
      overflow: hidden;
    }

    /*定义滑块 内阴影+圆角*/
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      overflow: hidden;
      background-color: #2a922a;
    }
  }
}
.doubt {
  width: 18px;
}
.search {
  display: flex;
  background: #f8f8f8;
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  .left {
    display: flex;
    align-items: center;
    flex: 1;
    input {
      font-size: 14px;
      width: 100%;
      border: none;
      outline: none;
      background: none;
    }
  }
  .btn {
    font-size: 14px;
    color: #999999;
  }
}
.btnBox {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0px -3px 4px 1px rgba(0, 0, 0, 0.04);
  padding: 5px 0;
  .btn {
    width: 261px;
    height: 40px;
    background: #2a922a;
    border-radius: 5px;
    font-size: 15px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
}
.list {
  .title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
  }
  .content {
  }
}
.popUp {
  width: 80%;
  border-radius: 4px;
  .header {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    padding: 20px 0 15px;
  }
  .content {
    margin: 15px 0;
    overflow: auto;
    margin: 0.4rem 0;
    max-height: 60vh;
    min-height: 20vh;
    height: 40vh;
    padding: 0 20px;
    font-size: 16px;
  }
  .bottom {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0754d3;
    text-align: center;
    padding: 15px;
    border-top: 1px solid #ececec;
  }
}
.def {
  text-align: center;
  margin: 10px auto 0;
  width: 100%;
  img {
    width: 30%;
    margin-right: -20px;
  }
  p {
    font-size: 14px;
    color: #aaa;
  }
}
</style>
